// extracted by mini-css-extract-plugin
export var actionButton = "_7e-1";
export var actions = "_7e-0";
export var copyableField = "_7e-2";
export var detailColumn = "_7e-3";
export var detailRow = "_7e-4";
export var dropdownTitle = "_7e-5";
export var fulfillmentDetails = "_7e-7";
export var fulfillments = "_7e-6";
export var icon = "_7e-8";
export var imgContainer = "_7e-9";
export var lineItems = "_7e-a";
export var link = "_7e-p";
export var orderCard = "_7e-k";
export var orderCollapseButton = "_7e-l";
export var orderCollapseButtonIcon = "_7e-m";
export var orderDetails = "_7e-b";
export var orderPillsContainer = "_7e-c";
export var pillWrapper = "_7e-d";
export var product = "_7e-e";
export var productDetails = "_7e-g";
export var productTopDetails = "_7e-h";
export var products = "_7e-f";
export var strong = "_7e-i";
export var tightText = "_7e-o";
export var tooltipHidden = "_7e-q";
export var variantQuantityRow = "_7e-j";
export var wrappingText = "_7e-n";