// extracted by mini-css-extract-plugin
export var aboveInput = "_5r-0";
export var actionButton = "_5r-4";
export var actionButtonContainer = "_5r-1";
export var actionIcon = "_5r-5";
export var aiInfoContainer = "_5r-2";
export var bold = "_5r-3";
export var checkIcon = "_5r-6";
export var clickingRecipientBar = "_5r-y";
export var commentThreadSwitch = "_5r-7";
export var draftImage = "_5r-8";
export var draftImageContainer = "_5r-9";
export var draftImages = "_5r-b";
export var editButton = "_5r-d";
export var editor = "_5r-c";
export var editorContainer = "_5r-v";
export var headerDivider = "_5r-e";
export var icon = "_5r-f";
export var info = "_5r-g";
export var internal = "_5r-k";
export var internalDivider = "_5r-h";
export var internalMessage = "_5r-i";
export var messageInputCard = "_5r-j";
export var messageInputForm = "_5r-l";
export var messageInputFormInternal = "_5r-m";
export var options = "_5r-n";
export var publicMessageCustomer = "_5r-p";
export var publicMessageMerchant = "_5r-o";
export var removeImageButton = "_5r-a";
export var replyButtons = "_5r-q";
export var showSignatureButton = "_5r-r";
export var spinner = "_5r-s";
export var visibilitySelector = "_5r-t";
export var warning = "_5r-u";
export var wrappingText = "_5r-w";
export var writingInternalNoteTopBorder = "_5r-x";