// extracted by mini-css-extract-plugin
export var actionButton = "_6q-0";
export var assigneeDetails = "_6q-1";
export var assigneeInfo = "_6q-2";
export var assigneeProfile = "_6q-4";
export var buttonContent = "_6q-5";
export var ccEmails = "_6q-a";
export var ccEmailsSection = "_6q-9";
export var chatbotContainer = "_6q-6";
export var collapseContainer = "_6q-c";
export var copyableField = "_6q-d";
export var customerInfoTitle = "_6q-f";
export var detailRow = "_6q-h";
export var details = "_6q-e";
export var detailsText = "_6q-l";
export var disclaimer = "_6q-b";
export var editAssigneeForm = "_6q-j";
export var editAssigneeInfo = "_6q-3";
export var editAssigneeModalContainer = "_6q-i";
export var editButton = "_6q-g";
export var email = "_6q-7";
export var header = "_6q-m";
export var headerDivider = "_6q-n";
export var icon = "_6q-o";
export var instaDesc = "_6q-x";
export var ordersReturnsSection = "_6q-v";
export var postPreview = "_6q-w";
export var profilePicture = "_6q-p";
export var rightContent = "_6q-8";
export var saveButton = "_6q-k";
export var strong = "_6q-q";
export var tagsAndAssigneeContainer = "_6q-t";
export var username = "_6q-r";
export var withBottomBorder = "_6q-u";
export var wrapper = "_6q-s";
export var wrappingText = "_6q-y";