// extracted by mini-css-extract-plugin
export var emailContainer = "_7a-3";
export var emailContentPaddedContainer = "_7a-9";
export var emailHeader = "_7a-5";
export var emailInfoIcon = "_7a-8";
export var emailShortenedSummary = "_7a-c";
export var infoDropdown = "_7a-0";
export var infoRowBoundedContent = "_7a-b";
export var internal = "_7a-4";
export var merchant = "_7a-7";
export var normal = "_7a-6";
export var replyCard = "_7a-d";
export var subtleButton = "_7a-1";
export var subtleButtonIconContainer = "_7a-2";
export var toggleViewRepliesButton = "_7a-a";
export var wrappingText = "_7a-e";