// extracted by mini-css-extract-plugin
export var bgImage = "_x-v";
export var body = "_x-u";
export var buttonBar = "_x-5";
export var card = "_x-0";
export var center = "_x-z";
export var closeButton = "_x-11";
export var collapseHeader = "_x-6";
export var collapseIcon = "_x-c";
export var collapseSection = "_x-a";
export var collapseTitle = "_x-9";
export var collapseTrigger = "_x-7";
export var collapsed = "_x-b";
export var dark = "_x-1";
export var grid = "_x-4";
export var header = "_x-e";
export var headerContainer = "_x-d";
export var headerSubtitle = "_x-i";
export var headerTitle = "_x-f";
export var inherit = "_x-8";
export var large = "_x-g";
export var left = "_x-10";
export var medium = "_x-h";
export var noPadding = "_x-3";
export var outlined = "_x-n";
export var scrollable = "_x-2";
export var section = "_x-m";
export var sectionHeader = "_x-q";
export var selected = "_x-o";
export var separator = "_x-p";
export var subsection = "_x-r";
export var subtitle = "_x-s";
export var subtotalCollapseHeader = "_x-w";
export var subtotalCollapseHeaderTitle = "_x-x";
export var subtotalItem = "_x-y";
export var table = "_x-j";
export var tableCell = "_x-l";
export var tableHeader = "_x-k";
export var title = "_x-t";