// extracted by mini-css-extract-plugin
export var chip = "_2d-a";
export var chipLabel = "_2d-b";
export var close = "_2d-8";
export var closeIcon = "_2d-9";
export var container = "_2d-3";
export var error = "_2d-7";
export var infoTooltip = "_2d-2";
export var innerContainer = "_2d-1";
export var input = "_2d-c";
export var medium = "_2d-6";
export var outerContainer = "_2d-0";
export var small = "_2d-5";
export var xSmall = "_2d-4";