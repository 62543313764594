// extracted by mini-css-extract-plugin
export var actions = "_2m-0";
export var actionsContainer = "_2m-3";
export var border = "_2m-1";
export var collapse = "_2m-2";
export var compact = "_2m-b";
export var content = "_2m-4";
export var contentChild = "_2m-8";
export var fullscreen = "_2m-6";
export var header = "_2m-9";
export var headerContainer = "_2m-c";
export var hidePadding = "_2m-5";
export var noScrollX = "_2m-d";
export var noScrollY = "_2m-e";
export var optOutTopPadding = "_2m-7";
export var profile = "_2m-f";
export var profileContainer = "_2m-g";
export var profileImage = "_2m-h";
export var profileMain = "_2m-i";
export var profileSubtitle = "_2m-k";
export var profileTitle = "_2m-j";
export var scrolledTop = "_2m-a";
export var title = "_2m-l";