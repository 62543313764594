// extracted by mini-css-extract-plugin
export var badgeColor = "_2s-f";
export var badgeModern = "_2s-g";
export var black = "_2s-1";
export var blue = "_2s-b";
export var blueLight = "_2s-a";
export var brand = "_2s-5";
export var error = "_2s-7";
export var gray = "_2s-3";
export var grayBlue = "_2s-9";
export var iconWrapper = "_2s-4";
export var indigo = "_2s-c";
export var orange = "_2s-e";
export var pillColor = "_2s-0";
export var pink = "_2s-d";
export var purple = "_2s-6";
export var success = "_2s-2";
export var warning = "_2s-8";