// extracted by mini-css-extract-plugin
export var baseCustomerActivityCardArrowRight = "_6f-e";
export var baseCustomerActivityCardArrowRightContainer = "_6f-d";
export var baseCustomerActivityCardContainer = "_6f-b";
export var baseCustomerActivityCardContainerHovered = "_6f-c";
export var customerActivityDataClaimIcon = "_6f-7";
export var customerActivityDataClaimIconContainer = "_6f-6";
export var customerActivityDataConversationIcon = "_6f-5";
export var customerActivityDataConversationIconContainer = "_6f-4";
export var customerActivityDataOrderIcon = "_6f-1";
export var customerActivityDataOrderIconContainer = "_6f-0";
export var customerActivityDataReturnIcon = "_6f-3";
export var customerActivityDataReturnIconContainer = "_6f-2";
export var customerActivityShoppingIconContainer = "_6f-8";
export var messageBubble = "_6f-9";
export var messageBubbleText = "_6f-a";