// extracted by mini-css-extract-plugin
export var active = "_76-b";
export var arrow = "_76-8";
export var arrowContainer = "_76-4";
export var container = "_76-0";
export var disabled = "_76-5";
export var dot = "_76-a";
export var dotContainer = "_76-9";
export var leftArrowContainer = "_76-6 _76-4";
export var rightArrowContainer = "_76-7 _76-4";
export var slide = "_76-3";
export var sliderContainer = "_76-1";
export var sliderOverflow = "_76-2";