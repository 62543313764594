// extracted by mini-css-extract-plugin
export var actionButton = "_79-1";
export var actions = "_79-0";
export var copyableField = "_79-2";
export var icon = "_79-3";
export var overflowVisible = "_79-4";
export var pillWrapper = "_79-5";
export var productDetails = "_79-7";
export var productPillsContainer = "_79-8";
export var productTopDetails = "_79-9";
export var products = "_79-6";
export var returnDetails = "_79-a";
export var strong = "_79-b";
export var variantQuantityRow = "_79-c";