// extracted by mini-css-extract-plugin
export var addButton = "_4x-0";
export var border = "_4x-8";
export var check = "_4x-h";
export var danger = "_4x-6";
export var dropdownContainer = "_4x-1";
export var editModalBody = "_4x-j";
export var footerContainer = "_4x-i";
export var fullWidth = "_4x-a";
export var hide = "_4x-9";
export var icon = "_4x-3";
export var input = "_4x-7";
export var inputFlex = "_4x-b";
export var inputWrapper = "_4x-c";
export var manualFocus = "_4x-5";
export var optionButton = "_4x-4";
export var pillWrapper = "_4x-e";
export var pointer = "_4x-2";
export var showOverflow = "_4x-f";
export var tagsFlex = "_4x-d";
export var xButton = "_4x-g";