// extracted by mini-css-extract-plugin
export var colorSquare = "_7r-0";
export var danger = "_7r-5";
export var lightPink = "_7r-8";
export var neutralBlue = "_7r-7";
export var neutralYellow = "_7r-6";
export var normal = "_7r-1";
export var primary = "_7r-2";
export var primaryLight = "_7r-3";
export var solidBlack = "_7r-g";
export var solidGreen = "_7r-b";
export var solidLightBlue = "_7r-d";
export var solidNavy = "_7r-c";
export var solidOrange = "_7r-f";
export var solidPink = "_7r-e";
export var solidRed = "_7r-9";
export var solidYellow = "_7r-a";
export var success = "_7r-4";