// extracted by mini-css-extract-plugin
export var centered = "_2h-6";
export var flexible = "_2h-5";
export var footer = "_2h-k";
export var footerBorder = "_2h-l";
export var header = "_2h-c";
export var headerBorder = "_2h-d";
export var headerDefault = "_2h-g";
export var headerMedium = "_2h-h";
export var headerPadding = "_2h-e";
export var iconAndTitle = "_2h-f";
export var large = "_2h-4";
export var largePadding = "_2h-a";
export var medium = "_2h-3";
export var mediumPadding = "_2h-9";
export var modal = "_2h-0";
export var modalContainer = "_2h-b";
export var noPadding = "_2h-7";
export var skinny = "_2h-1";
export var small = "_2h-2";
export var smallPadding = "_2h-8";
export var subtitle = "_2h-j";
export var title = "_2h-i";