// extracted by mini-css-extract-plugin
export var border = "_46-5";
export var button = "_46-f";
export var center = "_46-7";
export var centered = "_46-3";
export var closeButton = "_46-9";
export var disabledWrapper = "_46-g";
export var error = "_46-j";
export var fill = "_46-c";
export var footer = "_46-b";
export var footerAction = "_46-d";
export var footerButtons = "_46-e";
export var header = "_46-4";
export var icon = "_46-i";
export var info = "_46-m";
export var inline = "_46-6";
export var large = "_46-2";
export var main = "_46-a";
export var modal = "_46-0";
export var none = "_46-n";
export var small = "_46-1";
export var success = "_46-l";
export var tight = "_46-h";
export var titleIcon = "_46-8";
export var warn = "_46-k";