// extracted by mini-css-extract-plugin
export var container = "_5d-0";
export var content = "_5d-3";
export var customerTitle = "_5d-4";
export var duration = "_5d-5";
export var empty = "_5d-6";
export var gray = "_5d-7";
export var header = "_5d-8";
export var info = "_5d-9";
export var loading = "_5d-2";
export var loadingCard = "_5d-a";
export var oneLine = "_5d-b";
export var personName = "_5d-f";
export var pillContainer = "_5d-c";
export var selected = "_5d-1";
export var text = "_5d-d";
export var twoLines = "_5d-e";
export var unread = "_5d-g";
export var unreadIndicator = "_5d-h";