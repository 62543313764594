// extracted by mini-css-extract-plugin
export var alignCenter = "_4t-b";
export var buttonContent = "_4t-0";
export var cardList = "_4t-1";
export var centerContent = "_4t-2";
export var chevron = "_4t-3";
export var closePanelButton = "_4t-h";
export var conversationDetailContainer = "_4t-4";
export var detailWrapper = "_4t-5";
export var dropdownButton = "_4t-c";
export var dropdownTitle = "_4t-7";
export var hidden = "_4t-8";
export var icon = "_4t-9";
export var panel = "_4t-g";
export var portalButtonsWrapper = "_4t-a";
export var tableSummaryContainer = "_4t-6";
export var tableSummaryHeader = "_4t-e";
export var threeDotsButton = "_4t-d";
export var wide = "_4t-f";