// extracted by mini-css-extract-plugin
export var box = "_6w-1";
export var checked = "_6w-5";
export var descriptionSpacer = "_6w-9";
export var disabled = "_6w-4";
export var icon = "_6w-6";
export var input = "_6w-0";
export var labelSpacer = "_6w-8";
export var medium = "_6w-3";
export var small = "_6w-2";
export var text = "_6w-7";