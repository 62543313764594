// extracted by mini-css-extract-plugin
export var animationContainer = "_62-0";
export var conversationContainer = "_62-1";
export var detailsContent = "_62-2";
export var message = "_62-3";
export var modalContent = "_62-4";
export var modalFooter = "_62-6";
export var modalHeader = "_62-5";
export var name = "_62-7";
export var optionContainer = "_62-8";
export var optionTitle = "_62-9";
export var pillContainer = "_62-a";
export var selectedConversationsContainer = "_62-b";
export var selectedOptionContainer = "_62-c";