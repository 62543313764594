// extracted by mini-css-extract-plugin
export var danger = "_2r-6";
export var lightPink = "_2r-9";
export var medium = "_2r-i";
export var nearSquare = "_2r-l";
export var neutralBlue = "_2r-8";
export var neutralYellow = "_2r-7";
export var noCapitalize = "_2r-1";
export var normal = "_2r-2";
export var pill = "_2r-0";
export var primary = "_2r-3";
export var primaryLight = "_2r-4";
export var small = "_2r-j";
export var solidBlack = "_2r-h";
export var solidGreen = "_2r-c";
export var solidLightBlue = "_2r-e";
export var solidNavy = "_2r-d";
export var solidOrange = "_2r-g";
export var solidPink = "_2r-f";
export var solidRed = "_2r-a";
export var solidYellow = "_2r-b";
export var success = "_2r-5";
export var xsmall = "_2r-k";