// extracted by mini-css-extract-plugin
export var acitonButtons = "_5i-0";
export var block = "_5i-1";
export var blockContent = "_5i-7";
export var blockDescription = "_5i-8";
export var blockIcon = "_5i-9";
export var blockTitle = "_5i-6";
export var border = "_5i-a";
export var center = "_5i-k";
export var edge = "_5i-b";
export var edgeLabel = "_5i-c";
export var edgePath = "_5i-d";
export var error = "_5i-e";
export var flowchart = "_5i-f";
export var flowchartContent = "_5i-g";
export var highlighted = "_5i-3";
export var hover = "_5i-4";
export var icon = "_5i-h";
export var left = "_5i-l";
export var navButton = "_5i-i";
export var navButtons = "_5i-j";
export var right = "_5i-m";
export var selected = "_5i-2";
export var small = "_5i-5";