// extracted by mini-css-extract-plugin
export var createdDate = "_6p-1";
export var divider = "_6p-2";
export var headerDivider = "_6p-3";
export var messagesCard = "_6p-0";
export var messagesContainer = "_6p-7";
export var messagesContainerWrapper = "_6p-4";
export var pointerCapturingButtonBoxes = "_6p-b";
export var showLeftPanelButton = "_6p-a";
export var showMoreButton = "_6p-d";
export var showMoreButtonContainer = "_6p-c";
export var summaryTitle = "_6p-6";
export var systemMessageCardWrapper = "_6p-e";
export var topButtons = "_6p-9";
export var typingIndicator = "_6p-8";
export var withPadding = "_6p-5";