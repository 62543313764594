// extracted by mini-css-extract-plugin
export var actionButton = "_70-0";
export var bubble = "_70-2";
export var bubbleContext = "_70-6";
export var bubbleItems = "_70-7";
export var customer = "_70-3";
export var divider = "_70-8";
export var fileList = "_70-9";
export var infoAbove = "_70-a";
export var internal = "_70-4";
export var lightActionButton = "_70-1";
export var link = "_70-b";
export var merchant = "_70-5";
export var message = "_70-c";
export var profilePicture = "_70-e";
export var quillToolbarContainer = "_70-f";
export var senderDate = "_70-g";
export var senderDateCustomer = "_70-h";
export var senderName = "_70-d";
export var time = "_70-i";
export var uploadedImage = "_70-j";
export var username = "_70-k";
export var xsmall = "_70-l";