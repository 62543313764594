// extracted by mini-css-extract-plugin
export var brand = "_3b-4";
export var compact = "_3b-7";
export var extraSmall = "_3b-8";
export var icon = "_3b-0";
export var large = "_3b-1";
export var medium = "_3b-2";
export var outline = "_3b-9";
export var primaryDark = "_3b-5";
export var primaryLight = "_3b-3";
export var primaryModern = "_3b-6";