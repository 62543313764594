// extracted by mini-css-extract-plugin
export var active = "_2n-3";
export var activeWrapper = "_2n-0";
export var button = "_2n-1";
export var collapsed = "_2n-i";
export var divider = "_2n-7";
export var dividerSection = "_2n-6";
export var dropdown = "_2n-8";
export var dropdownChevron = "_2n-a";
export var expand = "_2n-9";
export var fullscreen = "_2n-r";
export var icon = "_2n-n";
export var label = "_2n-5 _k-0";
export var labelWithBubble = "_2n-p _k-0";
export var logo = "_2n-j";
export var logoSection = "_2n-m";
export var main = "_2n-q";
export var menu = "_2n-b";
export var menuHeader = "_2n-c _k-0";
export var menuItem = "_2n-d _k-0";
export var nav = "_2n-e";
export var noBackground = "_2n-2";
export var option = "_2n-4";
export var options = "_2n-g";
export var optionsContainer = "_2n-f";
export var page = "_2n-h";
export var rotate = "_2n-o";
export var section = "_2n-k";
export var settings = "_2n-t";
export var subitem = "_2n-s";
export var subitemActive = "_2n-x";
export var subitemLabel = "_2n-w";
export var subitemLine = "_2n-v";
export var subitemLineContainer = "_2n-u";
export var subsection = "_2n-l";